<template>
  <div>
    <div class="d-flex w-100 justify-content-between py-2 align-items-end">
      <div class="justify-content-center">
        <h4>
          <b-icon
            icon="arrow-left"
            @click="$router.back()"
            class="arrow-left"
            h1
          ></b-icon>
          {{ item.name }}
        </h4>
      </div>
      <div>
        <b-button
          v-on:click="editProperty = !editProperty"
          v-if="editProperty"
          variant="primary"
          class="px-4 py-2 edit-property-btn"
        >
          <b-icon icon="pencil-fill" class="mr-2"></b-icon>
          Edit Property
        </b-button>
        <!-- <b-button
          v-on:click="editProperty = !editProperty"
          v-if="!editProperty"
          variant="primary"
          class="px-4 py-2 mt-2 edit-property-btn"
        >
          <b-icon icon="file-earmark-richtext-fill" class="mr-2"></b-icon>
          Save Changes
        </b-button> -->
      </div>
    </div>

    <div class="properties-images">
      <b-row v-if="item.images">
        <b-col
          :cols="item.images.length == 1 ? '12' : '6'"
          class="properties-image-1"
          :class="item.images.length == 1 ? 'height' : 'full-height'"
        >
          <b-button
            variant="outline-"
            class="px-4 py-2 text-primary edit-media-btn"
          >
            <b-icon icon="image" class="mr-2"></b-icon>
            Edit Media
          </b-button>

          <img :src="item.images[0]" class="prop-img" alt="" />
        </b-col>
        <b-col cols="6" :class="item.images.length == 1 ? 'd-none' : 'd-block'">
          <b-row class="right-img-container">
            <b-col cols="12">
              <img :src="item.images[0]" class="prop-img" alt="" />
            </b-col>
            <b-col cols="12" class="properties-image-3">
              <img
                src="../../assets/images/Rectangle 305.png"
                class="prop-img"
                alt=""
              />
              <b-button class="px-2 py-1 img-counter" variant="outline-">
                <b-icon icon="camera2" class="mr-1"></b-icon> 3/10
              </b-button>
            </b-col>
          </b-row>
        </b-col>

        <div class="properties-desc-box w-100 d-flex justify-content-center">
          <div
            class="properties-desc d-flex rounded-sm justify-content-center bg-white px-4 w-75"
          >
            <div class="d-flex mr-5">
              <span
                ><h6>
                  <b-icon class="mr-2 mt-2" icon="geo-alt-fill"></b-icon>
                  {{
                    item.location?.address
                      ? item.location.address
                      : item.location_name
                  }}
                </h6></span
              >
            </div>
            <div class="d-flex mt-2 mr-5">
              <b-icon icon="calendar2-date-fill" class="mr-2"></b-icon>
              <span
                ><h6>{{ item.created_at | luxon }}</h6></span
              >
            </div>
            <div class="d-flex">
              <span>
                <h6>
                  <b-icon class="mt-2 mr-2" icon="calendar-check-fill"></b-icon>
                  {{ item.description }}
                </h6>
              </span>
            </div>
          </div>
        </div>
      </b-row>
    </div>

    <div v-if="editProperty" class="properties-info-box">
      <RmDisplayProperty :property="item" />
    </div>
    <div v-if="!editProperty" class="properties-info-box">
      <RmEditProperty :property="item" />
    </div>
  </div>
</template>

<script>
import RmDisplayProperty from './components/RmDisplayProperty.vue';
import RmEditProperty from './components/RmEditProperty.vue';

export default {
  data() {
    return {
      types: [{ name: 'House' }, { name: 'Land' }],
      available: [{ name: 'Active' }, { name: 'Inactive' }],
      editProperty: true,
      item: {},
    };
  },
  components: {
    RmDisplayProperty,
    RmEditProperty,
  },
  async mounted() {
    const loader = this.$loading.show();
    const id = this.$route.params.propertyId;

    try {
      let data = await this.$store.dispatch('locations/fetchPropertyById', {
        propertyId: id,
      });
      this.item = data.payload.product;
      loader.hide();
    } catch (error) {
      console.log(error);
      loader.hide();
    }
  },
};
</script>

<style lang="scss" scoped>
.edit-property-btn {
  font-size: 0.95rem;
}

.height {
  height: 50%;
}
.full-height {
  height: 100%;
}
.properties-images {
  margin-top: 3rem;
  position: relative;
}
.properties-image-1 {
  position: relative;
}
.prop-img {
  width: 100%;
  min-width: 100%;
  height: 25rem;
  object-fit: cover;
}
.right-img-container {
  gap: 1.8rem;
}
.properties-image-3 {
  position: relative;
}
.img-counter {
  position: absolute;
  font-size: 0.95rem;
  right: 2rem;
  bottom: 1rem;
  background: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(20px);
}

.properties-desc {
  margin-top: -4rem;
  z-index: 3;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}
.edit-media-btn {
  background: #ecf6fa;
  position: absolute;
  font-size: 0.95rem;
  top: 1rem;
  left: 2rem;
}

.arrow-left {
  cursor: pointer;
  margin-right: 1rem;
  // display: inline-block;
  // padding-right: 2rem;
}
</style>
