<template>
  <div>
    <b-row class="properties-info">
      <b-col cols="6" class="properties-content">
        <div class="properties-card properties-details bg-white rounded-sm">
          <h5 class="text-grey">Property Details</h5>
          <div class="properties-details-content mt-4">
            <b-row>
              <b-col class="w-100" cols="3">
                <div class="properties-types text-justify">
                  <b-button
                    variant="outline-"
                    class="properties-btn text-black-50 p-0"
                  >
                    <b-icon icon="border-all"></b-icon>
                    Type
                  </b-button>
                  <h6 class="mt-1">{{ property.product_type }}</h6>
                </div>
              </b-col>
              <b-col class="w-100 p-0 ml-3" cols="5">
                <div class="properties-price">
                  <b-button
                    variant="outline-"
                    class="properties-btn p-0 text-black-50"
                  >
                    <b-icon icon="cash-stack"></b-icon>
                    Unit Price
                  </b-button>
                  <h6 class="mt-1">N{{ property.unit_price / 100 }}</h6>
                </div>
              </b-col>
              <b-col class="w-100 pl-2">
                <div class="properties-available">
                  <b-button
                    variant="outline-"
                    class="properties-btn p-0 text-black-50"
                  >
                    <b-icon icon="house-fill"></b-icon>
                    Units Available
                  </b-button>
                  <h6 class="mt-1">{{ property.units_available }}</h6>
                </div>
              </b-col>
            </b-row>
            <b-row class="w-100 mt-2">
              <b-col cols="3">
                <div class="properties-unit">
                  <b-button
                    variant="outline-"
                    class="properties-btn p-0 text-black-50"
                  >
                    <b-icon icon="arrows-angle-expand"></b-icon>
                    Size/Unit
                  </b-button>
                  <h6 class="mt-1">
                    {{ property.size_per_unit }}{{ property.unit_of_measurement }}
                  </h6>
                </div>
              </b-col>
              <b-col cols="5" class="ml-4 p-0">
                <div class="properties-measurement">
                  <b-button
                    variant="outline-"
                    class="properties-btn p-0 text-black-50"
                  >
                    <b-icon icon="arrows-angle-expand"></b-icon>
                    Units of Measurement
                  </b-button>
                  <h6 class="mt-1">{{ property.unit_of_measurement }}</h6>
                </div>
              </b-col>
              <b-col class="ml-3 pl-1">
                <div class="properties-measurement text-justify">
                  <b-button
                    variant="outline-"
                    class="properties-btn p-0 text-black-50"
                  >
                    <b-icon icon="arrows-angle-expand"></b-icon>
                    Status
                  </b-button>
                  <h6
                    :class="
                      property.status == 'open' ? 'text-success' : 'text-danger'
                    "
                    class="mt-1"
                  >
                    {{ property.status == "open" ? "Active" : "Inactive" }}
                  </h6>
                </div>
              </b-col>
            </b-row>
          </div>
        </div>

        <div
          v-if="property.payment_plans && property.payment_plans[0]"
          class="properties-card properties-paymentPlan bg-white rounded-sm"
        >
          <h5>Payment Plan(installmental)</h5>
          <b-row class="mt-4">
            <b-col class="w-100"
              ><div class="properties-payment--durationmin">
                <b-button
                  variant="outline-"
                  class="p-0 properties-btn text-black-50"
                >
                  <b-icon icon="calendar-3"></b-icon>
                  Minimum Duration
                </b-button>
                <h6 class="mt-2">
                  {{ property.payment_plans[0].min_duration }} months
                </h6>
              </div></b-col
            >
            <b-col class="w-100 p-0"
              ><div class="properties-payment--durationmax">
                <b-button
                  variant="outline-"
                  class="properties-btn text-black-50 p-0"
                >
                  <b-icon icon="calendar-3"></b-icon>
                  Maximum Duration
                </b-button>
                <h6 class="mt-2">
                  {{ property.payment_plans[0].max_duration }} Months
                </h6>
              </div></b-col
            >
          </b-row>
          <b-row class="mt-3">
            <b-col class="w-100">
              <div class="properties-payment--percentageInc">
                <b-button
                  variant="outline-"
                  class="p-0 properties-btn text-black-50"
                >
                  <b-icon icon="percent"></b-icon>
                  Price Increase Percentage
                </b-button>
                <h6 class="mt-2">
                  {{ property.payment_plans[0].price_percentage }}%
                </h6>
              </div>
            </b-col>
            <b-col class="w-100 p-0">
              <div class="properties-payment--Deposit">
                <b-button
                  variant="outline-"
                  class="properties-btn p-0 text-black-50"
                >
                  <b-icon icon="percent"></b-icon>
                  Minimum Deposit Percentage
                </b-button>
                <h6 class="mt-2">
                  {{ property.payment_plans[0].minimum_deposit_percentage }}%
                </h6>
              </div>
            </b-col>
          </b-row>
        </div>

        <div
          class="properties-card properties-additionalFees bg-white rounded-sm"
        >
          <h5>Additional fees</h5>
          <b-row class="mt-3">
            <b-col class="w-100">
              <div class="Additional-Developmental-fee">
                <b-button
                  variant="outline-"
                  class="properties-btn p-0 text-black-50"
                >
                  <b-icon icon="cash-stack"></b-icon>
                  Developmental Fee
                </b-button>
                <h6 class="mt-2">N {{ property.developmental_fee / 100 }}</h6>
              </div>
            </b-col>
            <b-col class="w-100">
              <div class="Additional-Document-fee">
                <b-button
                  variant="outline-"
                  class="properties-btn p-0 text-black-50"
                >
                  <b-icon icon="cash-stack"></b-icon>
                  Document Fee
                </b-button>
                <h6 class="mt-2">N {{ property.documentation_fee / 100 }}</h6>
              </div>
            </b-col>
          </b-row>
        </div>
      </b-col>

      <b-col cols="6" class="properties-content">
        <div class="properties-features bg-white pt-4 px-4 rounded-sm">
          <h5>Features</h5>
          <b-row class="mt-4">
            <b-col
              v-for="(feature, idx) in property.features"
              :key="idx"
              class="mt-3"
              cols="6"
            >
              <input
                class="form-control text-dark"
                type="text"
                :placeholder="feature"
                readonly
              />
            </b-col>
          </b-row>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  export default {
    props: {
      property: {
        type: Object,
        required: true,
      },
    },
  };
</script>

<style lang="scss" scoped>
  .properties-content {
    padding-left: 1rem;
    padding-right: 1rem;

    .properties-card {
      padding: 1.5rem;
      margin-bottom: 1.5rem;
      min-height: 210px;
    }

    .properties-card h6 {
      font-size: 1rem;
    }
    .properties-features {
      min-height: 65%;
      padding: 1rem;
    }
    .properties-features input {
      font-size: 0.8rem;
    }
  }
  .properties-btn {
    font-size: 14.48px;
  }
  .properties-info {
    margin-top: 2rem;
  }
</style>
