<template>
  <div>
    <div class="btn-save-changes">
      <b-button
        variant="primary"
        class="px-4 py-2 mt-2 edit-property-btn"
        @click="saveChanges"
      >
        <b-icon icon="file-earmark-richtext-fill" class="mr-2"></b-icon>
        Save Changes
      </b-button>
    </div>

    <b-row class="properties-info">
      <b-col cols="6" class="properties-content">
        <div class="properties-card properties-details bg-white rounded-sm">
          <h5 class="text-grey">Property Details</h5>

          <div class="properties-details-content mt-4">
            <b-row>
              <b-col class="w-100" cols="3">
                <div class="properties-types text-justify">
                  <b-button
                    variant="outline-"
                    class="properties-btn text-black-50 p-0"
                  >
                    <b-icon icon="border-all"></b-icon>
                    Type
                  </b-button>

                  <div class="mt-2 select-box">
                    <select
                      v-model="property.product_type_id"
                      id="state-dropdown"
                      class="my-select"
                      disabled
                      required
                    >
                      <option
                        v-for="(type) in types"
                        :key="type.id"
                        selected
                        class="py-0 mt-0 mb-0 text-black"
                        :value="type.id"
                      >
                        {{ type.name }}
                      </option>
                    </select>
                    <b-icon icon="caret-down-fill"></b-icon>
                  </div>
                </div>
              </b-col>
              <b-col class="w-100 p-0 ml-3" cols="5">
                <div class="properties-price">
                  <b-button
                    variant="outline-"
                    class="properties-btn p-0 text-black-50"
                  >
                    <b-icon icon="cash-stack"></b-icon>
                    Unit Price
                  </b-button>

                  <input
                    class="mt-2 text-black rounded p-1"
                    type="text"
                    v-model="property.unit_price"
                    @blur="$v.property.unit_price.$touch()"
                  />
                  <template v-if="$v.property.unit_price.$error">
                    <p
                      v-if="!$v.property.unit_price.required"
                      class="error form-error"
                    >
                      Unit Price is required
                    </p>
                    <p
                      v-if="!$v.property.unit_price.integer"
                      class="error form-error"
                    >
                      Input must be an integer
                    </p>
                  </template>
                </div>
              </b-col>
              <b-col class="w-100 pl-2">
                <div class="properties-available">
                  <b-button
                    variant="outline-"
                    class="properties-btn p-0 text-black-50"
                  >
                    <b-icon icon="house-fill"></b-icon>
                    Units Available
                  </b-button>

                  <input
                    class="mt-2 w-100 text-black rounded p-1"
                    type="text"
                    v-model="property.units_available"
                    @blur="$v.property.units_available.$touch()"
                  />

                  <template v-if="$v.property.units_available.$error">
                    <p
                      v-if="!$v.property.units_available.required"
                      class="error form-error"
                    >
                      Units available is required
                    </p>
                    <p
                      v-if="!$v.property.units_available.integer"
                      class="error form-error"
                    >
                      Input must be an integer
                    </p>
                  </template>
                </div>
              </b-col>
            </b-row>
            <b-row class="w-100 mt-4">
              <b-col cols="3">
                <div class="properties-unit">
                  <b-button
                    variant="outline-"
                    class="properties-btn p-0 text-black-50"
                  >
                    <b-icon icon="arrows-angle-expand"></b-icon>
                    Size/Unit
                  </b-button>
                  <input
                    class="mt-2 w-100 text-black rounded p-1"
                    type="text"
                    v-model="property.size_per_unit"
                    @blur="$v.property.size_per_unit.$touch()"
                  />

                  <template v-if="$v.property.size_per_unit.$error">
                    <p
                      v-if="!$v.property.size_per_unit.required"
                      class="error form-error"
                    >
                      Size per unit is required
                    </p>
                    <p
                      v-if="!$v.property.size_per_unit.integer"
                      class="error form-error"
                    >
                      Input must be an integer
                    </p>
                  </template>
                </div>
              </b-col>
              <b-col cols="5" class="ml-4 p-0">
                <div class="properties-measurement">
                  <b-button
                    variant="outline-"
                    class="properties-btn p-0 text-black-50"
                  >
                    <b-icon icon="arrows-angle-expand"></b-icon>
                    Units of Measurement
                  </b-button>
                  <input
                    class="mt-2 w-100 text-black rounded p-1"
                    type="text"
                    v-model="property.unit_of_measurement"
                    @blur="$v.property.unit_of_measurement.$touch()"
                  />

                  <template v-if="$v.property.unit_of_measurement.$error">
                    <p
                      v-if="!$v.property.unit_of_measurement.required"
                      class="error form-error"
                    >
                      Unit of measurement is required
                    </p>
                  </template>
                </div>
              </b-col>
              <b-col class="ml-3 pl-1">
                <div class="properties-measurement text-justify">
                  <b-button
                    variant="outline-"
                    class="properties-btn p-0 text-black-50"
                  >
                    <b-icon icon="arrows-angle-expand"></b-icon>
                    Status
                  </b-button>
                  <div class="mt-2 select-box w-100">
                    <select
                      v-model="property.status"
                      id="state-dropdown"
                      class="my-select"
                      required
                    >
                      <option
                        selected
                        class="py-0 mt-0 mb-0 text-black"
                        value="open"
                      >
                        Active
                      </option>
                      <option
                        selected
                        class="py-0 mt-0 mb-0 text-black"
                        value="closed"
                      >
                        Inactive
                      </option>
                    </select>
                    <b-icon icon="caret-down-fill"></b-icon>
                  </div>
                </div>
              </b-col>
            </b-row>
          </div>
        </div>

        <div v-if="payment_plans" class="properties-card properties-paymentPlan bg-white rounded-sm">
          <h5>Payment Plan(installmental)</h5>
          <b-row class="mt-4">
            <b-col class="w-100"
              ><div class="properties-payment--durationmin">
                <b-button
                  variant="outline-"
                  class="p-0 properties-btn text-black-50"
                >
                  <b-icon icon="calendar-3"></b-icon>
                  Minimum Duration
                </b-button>
                <input
                  class="mt-2 text-black rounded p-1"
                  type="text"
                  v-model="payment_plans.min_duration"
                  @blur="$v.payment_plans.min_duration.$touch()"
                />
                <template v-if="$v.payment_plans.min_duration.$error">
                  <p
                    v-if="!$v.payment_plans.min_duration.required"
                    class="error form-error"
                  >
                    Minimum duration is required
                  </p>
                  <p
                    v-if="!$v.payment_plans.min_duration.integer"
                    class="error form-error"
                  >
                    Input must be an integer
                  </p>
                </template>
              </div></b-col
            >
            <b-col class="w-100 p-0 mr-4"
              ><div class="properties-payment--durationmax">
                <b-button
                  variant="outline-"
                  class="properties-btn text-black-50 p-0"
                >
                  <b-icon icon="calendar-3"></b-icon>
                  Maximum Duration
                </b-button>
                <input
                  class="mt-2 text-black rounded p-1"
                  type="text"
                  v-model="payment_plans.max_duration"
                  @blur="$v.payment_plans.max_duration.$touch()"
                />
                <template v-if="$v.payment_plans.max_duration.$error">
                  <p
                    v-if="!$v.payment_plans.max_duration.required"
                    class="error form-error"
                  >
                    Maximum duration is required
                  </p>
                  <p
                    v-if="!$v.payment_plans.max_duration.integer"
                    class="error form-error"
                  >
                    Input must be an integer
                  </p>
                </template>
              </div></b-col
            >
          </b-row>
          <b-row class="mt-4">
            <b-col class="w-100">
              <div class="properties-payment--percentageInc">
                <b-button
                  variant="outline-"
                  class="p-0 properties-btn text-black-50"
                >
                  <b-icon icon="percent"></b-icon>
                  Price Increase Percentage
                </b-button>
                <input
                  class="mt-2 text-black rounded p-1"
                  type="text"
                  v-model="payment_plans.price_percentage"
                  @blur="$v.payment_plans.price_percentage.$touch()"
                />

                <template v-if="$v.payment_plans.price_percentage.$error">
                  <p
                    v-if="!$v.payment_plans.price_percentage.required"
                    class="error form-error"
                  >
                    Price percentage is required
                  </p>
                  <p
                    v-if="!$v.payment_plans.price_percentage.decimal"
                    class="error form-error"
                  >
                    Input is number and must be at least to a decimal place
                  </p>
                </template>
              </div>
            </b-col>
            <b-col cols="6" class="p-0">
              <div class="properties-payment--Deposit">
                <b-button
                  variant="outline-"
                  class="properties-btn p-0 text-black-50"
                >
                  <b-icon icon="percent"></b-icon>
                  Minimum Deposit Percentage
                </b-button>
                <input
                  class="mt-2 text-black rounded p-1"
                  type="text"
                  v-model="payment_plans.minimum_deposit_percentage"
                  @blur="$v.payment_plans.minimum_deposit_percentage.$touch()"
                />
                <template
                  v-if="$v.payment_plans.minimum_deposit_percentage.$error"
                >
                  <p
                    v-if="!$v.payment_plans.minimum_deposit_percentage.required"
                    class="error form-error"
                  >
                    Minimum deposit percentage is required
                  </p>
                  <p
                    v-if="!$v.payment_plans.minimum_deposit_percentage.decimal"
                    class="error form-error"
                  >
                    Input is number and must be at least to a decimal place
                  </p>
                </template>
              </div>
            </b-col>
          </b-row>
        </div>

        <div
          class="properties-card properties-additionalFees bg-white rounded-sm"
        >
          <h5>Additional fees</h5>
          <b-row class="mt-5">
            <b-col class="w-100">
              <div class="Additional-Developmental-fee">
                <b-button
                  variant="outline-"
                  class="properties-btn p-0 text-black-50"
                >
                  <b-icon icon="cash-stack"></b-icon>
                  Developmental Fee
                </b-button>
                <input
                  class="mt-2 w-100 text-black rounded p-1"
                  type="text"
                  v-model="property.developmental_fee"
                  @blur="$v.property.developmental_fee.$touch()"
                />
                <template v-if="$v.property.developmental_fee.$error">
                  <p
                    v-if="!$v.property.developmental_fee.required"
                    class="error form-error"
                  >
                    Developmental fee is required
                  </p>
                  <p
                    v-if="!$v.property.developmental_fee.integer"
                    class="error form-error"
                  >
                    Input must be an integer
                  </p>
                </template>
              </div>
            </b-col>
            <b-col class="w-100">
              <div class="Additional-Document-fee">
                <b-button
                  variant="outline-"
                  class="properties-btn p-0 text-black-50"
                >
                  <b-icon icon="cash-stack"></b-icon>
                  Document Fee
                </b-button>
                <input
                  class="mt-2 w-100 text-black rounded p-1"
                  type="text"
                  v-model="property.documentation_fee"
                  @blur="$v.property.documentation_fee.$touch()"
                />
                <template v-if="$v.property.documentation_fee.$error">
                  <p
                    v-if="!$v.property.documentation_fee.required"
                    class="error form-error"
                  >
                    Documentation fee is required
                  </p>
                  <p
                    v-if="!$v.property.documentation_fee.integer"
                    class="error form-error"
                  >
                    Input must be an integer
                  </p>
                </template>
              </div>
            </b-col>
          </b-row>
        </div>
      </b-col>

      <b-col cols="6" class="properties-content">
        <div class="properties-features bg-white pt-4 px-5 rounded-sm">
          <h5>Features</h5>
          <b-row class="mt-4">
            <b-col
              v-for="(feature, idx) in property.features"
              :key="idx"
              class="mt-3"
              cols="6"
            >
              <div
                class="w-100 rmv-product-btn py-2 px-2 d-flex align-items-center justify-content-between"
              >
                {{ feature }}
                <b-icon
                  v-on:click="removeFeature(feature)"
                  role="button"
                  variant="outline-"
                  class="justify-self-end"
                  icon="x-circle-fill"
                ></b-icon>
              </div>
            </b-col>
            <b-col class="mt-3" cols="6">
              <div
                @click="addFeature = !addFeature"
                class="w-100 add-product-btn pointer d-flex py-2 px-2 justify-content-center align-items-center"
              >
                <b-icon
                  role="button"
                  variant="outline-"
                  class="mr-2"
                  icon="plus-circle-fill"
                ></b-icon>
                ADD FEATURES
              </div>
            </b-col>
          </b-row>
          <b-row class="mt-4" v-if="addFeature">
            <b-col cols="9">
              <input v-model="feature" class="w-100 h-100" type="text" />
            </b-col>
            <b-col cols="3">
              <b-button
                @click="saveFeature()"
                role="button"
                variant="outline-"
                class="bg-primary text-white w-100"
                >save</b-button
              >
            </b-col>
          </b-row>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { required, integer, decimal } from 'vuelidate/lib/validators';
export default {
  props: {
    property: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      payment_plans: this.property.payment_plans[0],
      addFeature: false,
      types: [],
      available: [{ name: 'Active' }, { name: 'Inactive' }],
      months: 'months',
      feature: '',
    };
  },
  computed: {},
  validations: {
    property: {
      unit_price: { required, integer },
      units_available: { required, integer },
      size_per_unit: { required, integer },
      unit_of_measurement: { required },
      developmental_fee: { required, integer },
      documentation_fee: { required, integer },
    },
    payment_plans: {
      min_duration: { required, integer },
      max_duration: { required, integer },
      price_percentage: { required, decimal },
      minimum_deposit_percentage: { required, decimal },
    },
  },
  async mounted () {
    this.property.unit_price = this.property.unit_price / 100;
    this.property.developmental_fee = this.property.developmental_fee / 100;
    this.property.documentation_fee = this.property.documentation_fee / 100;
    const { data } = await this.$store.dispatch('locations/fetchProductTypes');
    this.types = data.payload?.rows;
  },
  methods: {
    saveFeature() {
      this.addFeature = !this.addFeature;
      if (this.feature != '') {
        this.property.features.push(this.feature);
      }
      this.feature = '';
    },
    removeFeature(feature) {
      const filteredFeatures = this.property.features.filter(
        (feat) => feat != feature
      );
      this.property.features = filteredFeatures;
    },

    async saveChanges() {
      // this.$v.touch();
      if (this.$v.$invalid) {
        this.$store.dispatch('toast', {
          variant: 'error',
          message: 'Please fill all required fields',
        });
        return;
      }

      const loader = this.$loading.show();
      try {
        const body = {
          ...this.property,
          total_units: Number(this.property.total_units),
          unit_price: Number(this.property.unit_price) * 100,
          units_available: Number(this.property.units_available)
        };
        await this.$store.dispatch(
          'locations/editPropertyById',
          body
        );
        loader.hide();
        this.$store.dispatch('toast', {
          variant: 'success',
          message: 'Product Updated Successfully',
        });
        this.$emit('completed');
        // this.$router.back();
      } catch (error) {
        loader.hide();
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.edit-property-btn {
  font-size: 0.95rem;
}
.properties-content {
  padding-left: 1rem;
  padding-right: 1rem;

  .properties-card {
    padding: 1.5rem;
    margin-bottom: 1.5rem;
    min-height: 210px;
  }

  .properties-card h6 {
    font-size: 1rem;
  }
  .properties-features {
    min-height: 67%;
    padding: 1rem;
  }
}
.properties-btn {
  font-size: 14.48px;
}
.properties-info {
  margin-top: 2rem;
}

input {
  font-size: 0.9rem;
  color: black;
  border: 1px solid #d0d1d0;
  background: #f6f6f6;
}

.select-box {
  position: relative;
  display: flex;
  // height: 41%;
  border: 1px solid rgba(112, 112, 112, 0.432);
  background: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(20px);
  border-radius: 0.25em;
  margin-top: 13px;

  // .select-box select {
  //   border: 0;
  //   width: 100%;
  //   outline: 0;
  // }

  svg {
    position: absolute;
    right: 5%;
    bottom: 25%;
  }

  label {
    margin-bottom: 0.25rem;
  }

  .my-select {
    appearance: none;
    height: 100%;
    width: 100%;
    // display: grid;
    // min-width: 15ch;
    // max-width: 30ch;
    border: 1px solid rgba(112, 112, 112, 0.432);
    border-radius: 0.25em;
    border: 0;
    padding: 0.5em 0.6em;
    font-size: 0.9rem;
    cursor: pointer;
    line-height: 1.1;
    background-color: #fff;
    // background-image: linear-gradient(to top, #f9f9f9, #fff 33%);
    // background: rgba(255, 255, 255, 0.6);
    // backdrop-filter: blur(20px);
  }
}
.add-product-btn {
  background: #e8fbf4;
  border: 1.5px solid #1d9067;
  border-radius: 4px;
  color: #1d9067;
  font-size: 0.8rem;
}

.rmv-product-btn {
  background: #f6f6f6;
  border: 1px solid #d0d1d0;
  border-radius: 4px;
  color: #3c3e3c;
  font-size: 0.8rem;
}

.btn-save-changes {
  position: absolute;
  top: 0;
  right: 0;
}
</style>
